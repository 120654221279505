<template>
  <v-form ref="form">
    <v-text-field
      class="mr-2"
      label="Perfil"
      name="Perfil"
      placeholder="Perfil"
      v-model="perfil"
      :rules="perfilRules"
      :counter="50"
      required
    ></v-text-field>
    <div v-for="menu in menus" :key="menu.id">
      <v-checkbox v-model="selected" :label="menu.desMenu" :value="menu.id"></v-checkbox>
    </div>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <v-btn outline @click="voltar">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoPerfisAcesso } from "../../servicos/servicoPerfisAcesso"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoPerfilAcesso = new ServicoPerfisAcesso()
export default {
  components: {
    ProgressCircular
  },
  data: () => ({
    idRole: null,
    menus: [],
    perfil: "",
    selected: [],
    snackbar: false,
    text: "",
    perfilRules: [v => !!v || "Campo obrigatório"]
  }),
  created() {
    servicoPerfilAcesso.buscarMenusSelect().then(
      res => {
        if (res.status === 200) {
          this.menus = res.data
          this.verificaEditarECarregaCampos()
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    verificaEditarECarregaCampos() {
      if (this.$route && this.$route.params) {
        this.idRole = this.$route.params.id
      }
      if (this.idRole) {
        servicoPerfilAcesso.buscarPorIdParaEditar(this.idRole).then(res => {
          this.perfil = res.data.perfil
          this.selected = res.data.menus
          this.idRole = res.data.idRole
        })
      }
    },
    validarCampos() {
      if (this.selected.length > 0) return true
      else return false
    },
    submit() {
      if (this.validarCampos()) {
        this.abrirProgressCircular()

        servicoPerfilAcesso.salvar(this.idRole, this.perfil, this.selected).then(
          res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.voltar()
            } else {
              this.fecharProgressCircular()
              this.snackbar = true
              this.text = res.data[0].title + " - " + res.data[0].detail
            }
          },
          err => {
            // eslint-disable-next-line
            console.log(err)
            this.fecharProgressCircular()
            this.text = err
            this.snackbar = true
          }
        )
      } else {
        this.text = "Selecione pelo menos um menu para continuar."
        this.snackbar = true
      }
    },
    clear() {
      this.perfil = ""
      this.selected = []
      this.$refs.form.resetValidation()
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "PerfisAcesso" })
    }
  }
}
</script>
